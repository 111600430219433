<template>
	<div class="about">
		<v-header />
		<v-sidebar />
		<div class="content-box" :class="{ 'content-collapse': collapse }">

			<div class="content">
				<!-- 切换过渡 -->
				<router-view v-slot="{ Component }">
					<transition name="move" mode="out-in">
						<keep-alive :include="keeps">
							<component :is="Component" />
						</keep-alive>
					</transition>
				</router-view>
			</div>
		</div>
	</div>
</template>
<script>
	import vHeader from "@/components/header/index.vue";
	import vSidebar from "@/components/sidebar/index.vue";
	// import vTags from "@/components/Tags.vue";
	export default {
		components: {
			vHeader,
			vSidebar,
			// vTags
		},
		computed: {
			keeps() {
				return this.$router.options.routes.find(i=>i.path=='/layout').children.filter(i=>i.meta.keepAlive).map(i=>i.name)
			},
			collapse() {
				return this.$store.state.collapse;
			}
		}
	};
</script>