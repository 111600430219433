<template>
	<div class="header">
		<!-- 折叠按钮 -->
		<div class="collapse-btn" @click="collapseChage">
			<i v-if="!collapse" class="el-icon-s-fold"></i>
			<i v-else class="el-icon-s-unfold"></i>
		</div>
		<div class="logo"><img src="../../../public/img/logo.png" alt=""></div>
		<div class="header-right">
			<div class="header-user-con">
				<span class="contact_us" @click="goJump">登录/注册</span>
				<!-- <span class="contact_us urgent" @click="urgent.visible=true">紧急联系找回群</span> -->
				<span class="contact_us" @click="dialogVisible=true">联系我们及组合说明</span>
				<!-- 消息中心 -->
				<div class="btn-bell" v-if="false">
					<el-tooltip effect="dark" :content="message?`有${message}条未读消息`:`消息中心`" placement="bottom">
						<router-link to="/tabs">
							<i class="el-icon-bell"></i>
						</router-link>
					</el-tooltip>
					<span class="btn-bell-badge" v-if="message"></span>
				</div>
				<!-- 用户头像 -->
				<div class="user-avator" v-if="isLoginStatus()">
					<img :src="userInfo.avatar" />
				</div>
				<!-- 用户名下拉菜单 -->
				<el-dropdown v-if="isLoginStatus()" class="user-name" trigger="click" @command="handleCommand">
					<span class="el-dropdown-link">
						{{ userInfo.username }}
						<i class="el-icon-caret-bottom"></i>
					</span>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item v-for="it in commands" :key="it.label" :command="it">{{ it.label }}</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
		</div>

		<!-- 联系方式 -->
		<el-dialog
			title="提示"
			v-model="dialogVisible"
			width="30%"
			
		>
			<div class="tips">
				<h5>联系我们</h5>
				<div>
					<img src="../../../public/img/wechat.png" alt="">
					<p>添加qq群，加入组合跟踪群，交流转债交易。想加入团队，对网站建设有兴趣，请添加小助手说明</p>
				</div>
				<!-- <div>
					<img src="../../../public/img/assistant.jpg" alt="">
					<p>若无法加群请联系告知小助手</p>
				</div> -->

				<h5>多因子组合说明</h5>
				<div>
					<p>对转债的价格，溢价率，ma20乖离率,溢值率（债性+期权的内含价值）五个因子进行MAD法数据整理，Z-score标准化，分配相应影响因子后进行综合得分.进入前五位时，择机买入。掉出前10卖出。</p>
					<p>数据仅供参考，不提供投资建议，请盈亏自负</p>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
				</span>
			</template>
		</el-dialog>

		<!-- 紧急联系 -->
		<!-- <el-dialog
			title="紧急联系找回群"
			v-model="urgent.visible"
			width="30%"
			
		>
			<div class="tips">
				<h5>联系我们</h5>
				<div>
					<img src="../../../public/img/wechat.png" alt="">
					<p>由于二群、三群被恶意投诉被封，请大家重新扫码找到组织！给大家带来的不便请见谅。</p>
				</div>
				<div>
					<img src="../../../public/img/assistant.jpg" alt="">
					<p>若无法加群请联系告知小助手</p>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" @click="urgent.visible = false">确 定</el-button>
				</span>
			</template>
		</el-dialog> -->
	</div>
</template>
<script>
import utils from '@/utils'
const { getUserInfo, logout, isLoginStatus } = utils
export default {
	data() {
		return {
			fullscreen: false,
			message: 2,
			dialogVisible: true, // 说明弹框
			urgent: {  // 紧急联系
				visible: true,
			},
			// 头像下拉选项
			commands: [
				{
					label: '退出',
					value: 'logout',
					onclick: this.logout
				}
			],
		};
	},
	computed: {
		userInfo(){
			return getUserInfo()
		},
		collapse() {
			return this.$store.state.collapse;
		}
	},
	methods: {
		// 初始化弹窗
		initDialog() {
			let isFirst = localStorage.getItem('isFirst') || false
			isFirst && (this.dialogVisible=false)

			// 第一次紧急通知
			let urgentFrist = localStorage.getItem('urgentFrist') || false
			urgentFrist && (this.urgent.visible=false)
		},

		// 用户名下拉菜单选择事件
		handleCommand(command) {
			console.log(command)
			command.onclick && command.onclick()
		},

		// 侧边栏折叠
		collapseChage() {
			this.$store.commit("hadndleCollapse", !this.collapse);
		},

		// 退出
		async logout(){
			const res=await this.$api.logout()
			logout()
			location.reload()
			// if (res) {
			// }
		},
		// 登录状态
		isLoginStatus(){
			return isLoginStatus()
		},

		// 跳转
		goJump(){
			// window.open('https://ask.ezhai.net.cn/', '__blank')
			this.$router.push({
				path: '/login'
			})
		}
	},
	mounted() {
		this.initDialog()
		// if (document.body.clientWidth < 1500) {
		// 	this.collapseChage();
		// }
	},
	watch: {
		dialogVisible(){
			if (!this.dialogVisible) localStorage.setItem('isFirst', true)
		},

		// 紧急通知
		'urgent.visible'(){
			if (!this.urgent.visible) localStorage.setItem('urgentFrist', true)
		}
	}
};
</script>
<style scoped lang="scss">
.header {
	background-color: #242f42;
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 70px;
	font-size: 22px;
	color: #fff;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.collapse-btn {
	float: left;
	padding: 0 21px;
	cursor: pointer;
	line-height: 70px;
}

.header .logo {
	flex: 1;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	// float: left;
	/* width: 250px;
	line-height: 70px; */
	img{
		height: 50px;
	}
}

.header-right {
	float: right;
	padding-right: 50px;
}

.header-user-con {
	display: flex;
	height: 70px;
	align-items: center;
}

.btn-fullscreen {
	transform: rotate(45deg);
	margin-right: 5px;
	font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
	position: relative;
	width: 30px;
	height: 30px;
	text-align: center;
	border-radius: 15px;
	cursor: pointer;
}

.btn-bell-badge {
	position: absolute;
	right: 0;
	top: -2px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: #f56c6c;
	color: #fff;
}

.btn-bell .el-icon-bell {
	color: #fff;
}

.user-name {
	margin-left: 10px;
}

.user-avator {
	margin-left: 20px;
}

.user-avator img {
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.el-dropdown-link {
	color: #fff;
	cursor: pointer;
}

.el-dropdown-menu__item {
	text-align: center;
}


.header{
	.header-right{
		.header-user-con{
			.contact_us{
				font-size: 14px;
				text-decoration-line: underline;
				cursor: pointer;
				&:not(:last-of-type){
					margin-right: 12px;
				}
			}
			.urgent{
				font-size: 16px;
				color: red;
				// margin-right: 10px;
			}
		}
	}
}


.el-dialog{
	.tips{
		h5{
			font-size: 20px;
			margin: 10px 0;
		}
		> div{
			padding: 0 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			img{
				width: 240px;
			}
			p{
				width: 100%;
				text-align: left;
				text-indent: 2em;
			}
		}
	}
}
</style>
